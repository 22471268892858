<template>
  <div>
    <v-img
      :src="require('../assets/trees-1587301_1280.jpg')"
      max-height="200"
      dark
    >
      <v-container>
        <div class="text-lg-h3 text-md-h3 text-sm-h4 text-xs-h4 mt-10">
          Baumkatalog
        </div>
        <div class="text-lg-h5 text-sm-h6">Lerne die Bäume zu erkennen!</div>
      </v-container>
    </v-img>
    <v-container>
      <v-row>
        <v-col cols="12" xs="6" md="6">
      <v-sheet
      max-height="300"
      max-width="500">
          <v-carousel
              v-bind:cycle="cycle"
              interval="10000"
              hide-delimiter-background
              show-arrows-on-hover
              height="300"
            >
              <v-carousel-item
                v-for="(image, i) in trees[treeIndex].images"
                :key="i"
              >
                <v-img :src="image.src" :alt="image.alt"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
      </v-sheet>
        </v-col>
        <v-col cols="12" xs="6" md="6">
          <v-card flat>
          <v-card-title v-if="!hideTitle">
            {{ trees[treeIndex].name | capitalized }}
          </v-card-title>
          <v-card-text>
            {{ trees[treeIndex].question }}
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import treesData from "./trees.js";

export default {
  name: "Tree",
  props: ["treeName", "cycle", "hideTitle"],
  data: () => ({
    tree: 0,
    trees: treesData,
  }),
  computed: {
    treeIndex: function () {
      var temp = 0;
      temp = this.trees.findIndex((x) => x.name == this.treeName);
      console.log(temp);
      return temp;
    },
  },
  filters: {
    capitalized: function (treeName) {
      return treeName.charAt(0).toUpperCase() + treeName.slice(1);
    },
  },
  metaInfo: {
    title: 'Bilder und Infos des Baums',
    meta: [
      { property: "description", content: 'Hier findest du Bilder und Informationen zu allen Bäumen des Baumquiz.' },
      { property: 'og:description', content: 'Hier findest du Bilder und Informationen zu allen Bäumen des Baumquiz.' },
    ],
  }
};
</script>
